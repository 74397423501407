<template>
  <top-nav :theme="data.theme" :module="data.sectionMode" @theme="onChangeTheme">
    <v-responsive class="w-100 home">
      <div class="0">
        <h3>Dashboard here</h3>
      </div>
    </v-responsive>
  </top-nav>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, watch } from 'vue'
import TopNav from "@/components/topNav";

const data = reactive({
    oid:'',
    sectionMode:'org',
    theme:'light'
});

function sectionBtnMode(btn){
  return btn==data.sectionMode?"tonal":"plain"
}
function changeSectionMode(btn) {
  data.sectionMode=btn;
  localStorage.setItem('module',data.sectionMode);
}
function onChangeTheme() {
  data.theme=data.theme==="dark"?"light":"dark";
  localStorage.setItem('theme',data.theme);
}
onMounted(async ()=>{
   data.theme=localStorage.getItem('theme') || 'dark';
   data.sectionMode=localStorage.getItem('module') || 'org';
   data.oid=localStorage.getItem('oid');
});
</script>

<style lang="scss">

</style>
