// Styles
import 'vuetify/styles'

// Composable
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import {
  mdiWeatherNight,
  mdiWeatherSunny,
  mdiDelete,
  mdiPlus,
  mdiSend,
  mdiContentSave,
  mdiMenu,
  mdiClockOut,
  mdiRunFast,
  mdiFinance,
  mdiReload,
  mdiClose,
  mdiLogout,
  mdiAccountHardHat,
  mdiMapMarker,
  mdiMapMarkerOff,
  mdiTextBoxOutline,
  mdiFileTree,
  mdiChevronDown,
  mdiChevronUp,
  mdiChevronLeft,
  mdiChevronRight,
  mdiContentCopy,
  mdiCurrencyUsd,
  mdiComment,
  mdiPaperclip
} from '@mdi/js'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      chart:mdiFinance,
      menu: mdiMenu,
      clock: mdiClockOut,
      copy:mdiContentCopy,
      dark:mdiWeatherNight,
      delete:mdiDelete,
      cancel:mdiClose,
      light:mdiWeatherSunny,
      logout:mdiLogout,
      add:mdiPlus,
      reload:mdiReload,
      run:mdiRunFast,
      send:mdiSend,
      save:mdiContentSave,
      map:mdiMapMarker,
      address:mdiMapMarker,
      attachments:mdiPaperclip,
      contractor:mdiAccountHardHat,
      fees:mdiCurrencyUsd,
      mapClear:mdiMapMarkerOff,
      fields:mdiTextBoxOutline,
      notes:mdiComment,
      workFlow:mdiFileTree,
      down:mdiChevronDown,
      up:mdiChevronUp,
      right:mdiChevronRight,
      left:mdiChevronLeft,
    },
    sets: {
      mdi,
    },
  },
})
