<template>
  <no-nav :theme="data.theme" :module="data.sectionMode" :oid="data.oid" :pid="data.pid" @theme="onChangeTheme">
    <v-responsive class="w-100 home">
      <a :href="loginUrl()">Login</a>
    </v-responsive>
  </no-nav>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, watch } from 'vue'
import NoNav from "@/components/noNav";
import u from "@/lib/util";

const B2C_URL = import.meta.env.VITE_B2C_URL;

const data = reactive({
  theme:'light'
});

function onChangeTheme() {
  data.theme=data.theme==="dark"?"light":"dark";
  localStorage.setItem('theme',data.theme);
}

const loginUrl = function () {
  let port=window.location.port;
  if(port==="443" || port==="80") port="";
  if(port) port=":"+port;
  let returnUrl=window.location.protocol + "//" + window.location.host+port;
  let urlReplace = {
    redirect:encodeURIComponent(returnUrl+"/")
  };
  return u.macroReplace(B2C_URL,urlReplace);
}

onMounted(async ()=>{
  data.theme=localStorage.getItem('theme') || 'dark';
});
</script>

<style lang="scss">

</style>
