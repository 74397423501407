import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/home.vue";
import Login from "@/views/login.vue";

import Admin from "@/admin/admin.vue";
import Organization from "@/admin/adminOrganization";
import Member from "@/admin/adminMember";
import Holiday from "@/admin/adminHoliday";

import Work from "@/work/work.vue";
import WorkCreate from "@/work/workCreate.vue";
import WorkEdit from "@/work/workEdit.vue";

import Map from "@/map/map.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "org",
        name: "org",
        component: Organization
      },
      {
        path: "member",
        name: "member",
        component: Member
      },
      {
        path: "holiday",
        name: "holiday",
        component: Holiday
      }
    ]
  },

  {
    path: "/work",
    component: Work,
    children: [
      {
        path: "create",
        name: "workCreate",
        component: WorkCreate,
        props: {
          appFlag: "INTERNAL"
        }
      },
      {
        path: "edit/:id",
        name: "workEdit",
        component: WorkEdit,
        props: true
      }
    ]
  },
  {
    path: "/map",
    name:"map",
    component: Map
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
