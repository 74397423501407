(() => {
  let proxyInstances = new WeakSet()

  // Optionally save the original in global scope:
  Window.originalProxy = Proxy

  Proxy = new Proxy(Proxy, {
    construct(target, args) {
      let newProxy = new Window.originalProxy(...args)
      proxyInstances.add(newProxy)
      return newProxy
    },
    get(obj, prop) {
      if (prop == Symbol.hasInstance) {
        return (instance) => {
          return proxyInstances.has(instance)
        }
      }
      return Reflect.get(...arguments)
    }
  })
})()

import App from './App.vue'
import router from './router'
import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'

const app = createApp(App);
app.provide('settings', {})

registerPlugins(app);
app.use(router);

app.mount('#app');
