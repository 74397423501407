<script setup lang="ts">
import { onBeforeMount, reactive, inject } from 'vue'
import MyWorker from './my-worker?worker'

import ReloadPrompt from './ReloadPrompt.vue'
import event from './lib/event.js';
import u from './lib/util.js';
import { db } from '@/lib/db.js';
import { useRouter, useRoute } from 'vue-router'

const worker = new MyWorker();

const env = import.meta.env.VITE_ENV;
const B2C_URL = import.meta.env.VITE_B2C_URL;
const session = reactive({authenticated:true});

const sync = async (data) => {
  data=data||{};
  let oid = data.oid||localStorage.getItem('oid');
  data.oid=oid;
  console.debug('sync postingMessage',oid);
  if(data.payload) data.payload=JSON.stringify(data.payload);
  worker.postMessage(data)
}
const messageFromWorker = async ({ data: { msg, data } }) => {
  console.debug('messageFromWorker sync message',{ msg, data });
  event.emit(msg,data)
}
const checkSync = function (data) {
  event.emit('sync',data);
}

async function getUserInfo() {
  const response = await fetch('/.auth/me');
  const payload = await response.json();
  const { clientPrincipal } = payload;
  return clientPrincipal;
}

onBeforeMount(async () => {
  const router = useRouter();
  const route = useRoute();

  let mid = localStorage.getItem('mid')
  if(!mid && env!='dev') {
    let user=await getUserInfo();
    if(user==null || user==undefined) {
      console.log("user is null");
      await router.push({
        name: 'Login',
        query: {
          ...route.query
        }
      });
    } else {
      console.log("user:",user);
      session.authenticated = true;
    }
  } else session.authenticated = true;

  let oid = localStorage.getItem('oid')
  if (!oid && db['org']) {
    await db.table('org').orderBy("oid").first((item) => {
      if(!oid && item) oid = item.oid;
    });
  }
  oid = oid || "00000000-0000-0000-0000-000000000000";

  const settings = inject('settings') as Object;
  if (oid && db['org']) {
    await db['org'].where({oid:oid}).each((org)=>{
      u.extend(settings,org);
      delete settings['created'];
      delete settings['createdBy'];
      delete settings['modified'];
      delete settings['modifiedBy'];
      delete settings['active'];
    })
  }

  localStorage.setItem('oid', oid);
  event.on('sync', 'app', u.throttle(sync,30000));      // throttle no more than every 30 seconds
  event.on('transport', 'app',sync);                         // transport sync commands can go right away
  event.on('transmit', 'app', u.throttle(sync,10000));  // actual transmissions to server throttled every 10 seconds
  worker.addEventListener('message', messageFromWorker);

  setInterval(()=>{ event.emit('sync:transmit', {})},15000);
  event.emit('sync', {oid});
})
</script>

<template>
  <div>
  <router-view></router-view>
  <ReloadPrompt />
  </div>
</template>

<style lang="scss">
.v-row.selectable:hover {
  background-color: #333333;
  cursor: pointer;
}
</style>
